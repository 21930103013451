<div class="row">
  <div class="col-12">
    <div class="container container-step">
      <div class="row">
        <div class="col-12">
          <h2>{{ 'intro-view.headline' | translate }}</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div
            *ngIf="personalInfo"
            class="personal-info"
          >
            {{
              [
                personalInfo.fullName,
                personalInfo.birthDate | date : 'shortDate' : 'UTC',
                personalInfo.gender
              ] | mrcArrayToString
            }}<br />
            {{ 'shared.contract-id' | translate }}:
            {{ personalInfo.contractId }}
          </div>

          <div class="paragraph">
            {{ 'intro-view.textParagraph1' | translate }}
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-12"
          style="position: relative"
        >
          <div
            #scroller
            class="scroll-container paragraph"
            (scroll)="onScroll($event)"
            [ngClass]="{ 'scroll-container-error': hasErrorNotReadLegalNotes }"
            [innerHTML]="preContractualDisclosureHtml$ | async"
          ></div>
          <button
            mat-fab
            id="e2e-paragraph-19-scroll-button"
            class="clear-button-styles bottom-center-fab"
            [color]="hasErrorNotReadLegalNotes ? 'warn' : 'primary'"
            (click)="scrollMore()"
            [disabled]="isAtBottomOfLegalNotes"
          >
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <div class="error-container paragraph">
            <div *ngIf="hasErrorNotReadLegalNotes">
              {{ 'intro-view.please-read' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          {{ 'intro-view.please-download' | translate }}
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <div class="container">
            <div class="row">
              <div class="col-2">
                <img
                  src="assets/img/pdf.png"
                  alt="PDF Image"
                />
              </div>
              <div class="col-10">
                <span
                  class="classic-link"
                  (click)="onClickDownloadPdf()"
                >
                  {{ preContractualDisclosureDownloadLink$ | async }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <div class="paragraph">
            <mat-checkbox
              id="e2e-paragraph-19-confirm-checkbox"
              class="legal-checkbox"
              [ngClass]="{ disabled: !legalNotesHaveBeenRead }"
              #legalNotesCheckBox
              (change)="tickLegalNotes()"
              required
            >
              <div
                class="legal-checkbox-label"
                [innerHtml]="preContractualDisclosureLabel$ | async"
                [ngClass]="{ 'not-confirmed-error': hasErrorNotConfirmed }"
                idvClickTracker="idv_confirmation_legal_notes_paragraph_19"
              ></div>
              <div class="error-container">
                <div *ngIf="hasErrorNotConfirmed">
                  {{ 'intro-view.please-confirm' | translate }}
                </div>
              </div>
            </mat-checkbox>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <button
            id="e2e-paragraph-19-confirm-button"
            class="button-proceed"
            [ngClass]="{ 'button-disabled': !hasUserConfirmedLegalNotes }"
            type="button"
            mat-raised-button
            color="primary"
            (click)="onClickProceed()"
          >
            {{ 'intro-view.next' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
