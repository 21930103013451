<div *ngFor="let category of riskCategories; let i = index">
  <div class="row">
    <div class="col">
      <h2>
        {{ riskCategories.length > 1 ? i + 1 + '.' : '' }}
        {{ 'step-navigation.step-labels.' + category.i18nKey | translate }}
      </h2>
    </div>
  </div>

  <div *ngFor="let risk of risksByCategory[category.key]">
    <idv-summary-entity [risk]="risk"></idv-summary-entity>
  </div>
</div>
