import { IdvFlavour } from '../../../idv-lib/src/lib/interfaces/idv-flavour';

export const insFlavor: IdvFlavour = {
  name: 'insured',
  loginStepCount: 0,
  showLoginSteps: false,
  routeAfterLogin: 'intro',
  isEditRiskEnabled: false,
  components: {},
  summaryPdfPath: 'myRequest/pdf/summary',
  apiPrefix: 'ins',
};
