import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Request, Risk } from '../../../../../idv-lib/src/lib/interfaces';
import { AppRoute } from '../../../../../idv-lib/src/lib/interfaces/app-routing.const';
import {
  hasFilledRiskCategory,
  RequestService,
  riskCategories,
  RiskCategory,
} from '../../../../../idv-lib/src/lib/services/request/request.service';
import { CompositeStepState } from '../../../../../idv-lib/src/lib/services/step-navigation/composite-step-state';
import { StepState } from '../../../../../idv-lib/src/lib/services/step-navigation/step-state';
import {
  StepName,
  StepStateService,
} from '../../../../../idv-lib/src/lib/services/step-navigation/step-state.service';
import { TenantService } from '../../../../../idv-lib/src/lib/services/tenant/tenant.service';
import {
  isRiskTreeFinished,
  isRiskVisibleToInsured,
} from '../../../../../idv-lib/src/lib/utils/risks';

@Injectable({ providedIn: 'root' })
export class InsStepStateService extends StepStateService {
  constructor(
    requestService: RequestService,
    router: Router,
    tenantService: TenantService
  ) {
    super(requestService, router, tenantService);
  }

  protected getStepAfterRiskSteps(): string {
    return AppRoute.complete;
  }

  protected createRiskSteps(risks: Risk[]): StepState[] {
    return risks
      .map((risk, idx, arr) => {
        return this.getStepStatesForRiskRecursively(
          risk,
          !this.getStep(StepName.intro)?.isCompleted,
          idx === arr.length - 1
        );
      })
      .reduce((flat, toFlatten) => flat.concat(toFlatten), []);
  }

  private getStepStatesForRiskRecursively(
    risk: Risk,
    locked: boolean,
    isLastChild: boolean,
    level = 0
  ): StepState[] {
    const riskStepStates = new Array<StepState>();

    if (isRiskVisibleToInsured(risk)) {
      riskStepStates.push({
        name: risk.id,
        isCompleted: isRiskTreeFinished(risk),
        isRisk: true,
        risk: risk,
        isLocked: locked,
        isLastChild:
          isLastChild &&
          !risk.routedRisks?.some((r) => isRiskVisibleToInsured(r)),
        class: `nav-item-small nav-item-level-${level}`,
      });

      risk.routedRisks?.forEach((routedRisk, idx, arr) => {
        riskStepStates.push(
          ...this.getStepStatesForRiskRecursively(
            routedRisk,
            locked,
            idx === arr.length - 1,
            level + 1
          )
        );
      });
    }

    return riskStepStates;
  }

  protected createRiskComposite(
    riskGroupName: string,
    risks: Risk[]
  ): StepState {
    const riskSubSteps = this.createRiskSteps(risks);
    return new CompositeStepState(riskGroupName, riskSubSteps);
  }

  updateSteps(request: Request): CompositeStepState {
    const stepStates: StepState[] = [];
    this.stepStateRoot = new CompositeStepState('root', stepStates);

    stepStates.push({
      name: StepName.intro,
      isCompleted: request?.prologueQuestionnaire?.completed,
    });

    if (request.underwriterHints != null) {
      stepStates.push({
        name: StepName.underwriterHints,
        isCompleted: false,
      });
    }

    riskCategories
      .filter(hasFilledRiskCategory(request, isRiskVisibleToInsured))
      .forEach((riskCategory: RiskCategory) => {
        stepStates.push(
          this.createRiskComposite(
            riskCategory.i18nKey,
            request[riskCategory.key].risks
          )
        );
      });

    stepStates.push({
      name: StepName.complete,
      isCompleted: false,
      isLocked: !this.areAllRequiredStepsCompleted(),
    });

    return this.stepStateRoot;
  }

  areAllRequiredStepsCompleted() {
    return (
      this.stepExists(StepName.intro) &&
      this.getStep(StepName.intro)?.isCompleted &&
      this.areAllQuestionnairesCompleted()
    );
  }
}
