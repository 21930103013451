import { Config } from '../../../idv-lib';
import { IdvEnvironment } from '../../../idv-lib';
import { insFlavor } from './ins-flavor';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const defaultConfig: Config = {
  versionInfo: {
    MIRApply: 'default config version',
  },
  tracking: {
    disableTelemetry: true,
    enableDebug: true,
    isStorageUseDisabled: true,
    maxBatchInterval: 0,
  },
  links: {
    imprint: {
      isShown: true,
      url: 'https://www.munichre.com/de/allgemein/impressum.html',
    },
    legalNotice: {
      isShown: true,
      url: 'https://www.munichre.com/de/allgemein/rechtliche-hinweise.html',
    },
    privacyStatement: {
      isShown: true,
      url: 'https://www.munichre.com/de/allgemein/datenschutz.html',
    },
    faq: {
      isShown: true,
    },
    tuevReport: {
      isShown: true,
      url: 'https://www.tuev-sued.de/management-systeme/ms-zert/zadv',
    },
  },
  isTest: false,
  preferredLanguage: 'de',
  showLanguageSelector: true,
  allowsPaperDownload: false,
  captcha: {
    siteKey: null,
    captchaIsRequired: false,
  },
  texts: {},
  bulletPointsPerProductParagraphTwo: {},
  bulletPointsPerProductParagraphThree: {},
  displayCertificateIcon: true,
  adobeAnalyticsUrl: null,
  preContractualDisclosurePdfDownloadName: 'pre-contractual-disclosure.pdf',
  paymentInformationFormat: 'iban',
  allowRisksWithGdqForPhysician: true,
};

export const environment: IdvEnvironment = {
  production: false,
  apiHost: '/api/v1',
  apiConfigHost: '/api/v1/idv/config/assets',
  assetHost: '/assets',
  // NOTE: sym-link-configuration doesn't work, at least on Windows
  //       tried with `mklink /D` and `mklink /J`; option /H is not allowed, even with admin-rights
  // apiConfigHost: 'http://localhost:4400/assets/sym-link-configuration',
  isErrorScreenEnabled: false,
  isErrorDisplayedAsDefault: true,
  httpRequests: {
    timeoutInMs: 300000,
    retryIntervalInMs: 1000,
    maximumRetries: 0,
  },
  defaultConfig,
  flavor: insFlavor,
  application: 'insured',
};
