import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InsuredHints } from '../../../../../idv-lib/src/lib/interfaces';
import {
  IdvEnvironment,
  IDV_ENV,
} from '../../../../../idv-lib/src/lib/interfaces/idv-environment';

@Injectable({ providedIn: 'root' })
export class InsuredHintsService {
  constructor(
    private httpClient: HttpClient,
    @Inject(IDV_ENV) private environment: IdvEnvironment
  ) {}

  public answer(freetext: string): Observable<void> {
    let insuredHint: InsuredHints = {
      freeText: freetext,
    };
    return this.httpClient.post<void>(
      `${this.environment.apiHost}/ins/myRequest/hints/answer`,
      insuredHint
    );
  }
}
