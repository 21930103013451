<div class="row">
  <div class="col-12">
    <div class="container container-step">
      <div class="row">
        <div class="col-12">
          <h2>
            {{ 'step-navigation.step-labels.underwriter-hints' | translate }}
          </h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div [innerText]="underwriterHintsFreeText$ | async"></div>
        </div>
      </div>

      <div
        [formGroup]="formGroup"
        class="row"
      >
        <div class="col-12">
          <mat-form-field class="form-field-full-width">
            <textarea
              formControlName="freeText"
              name="freeText"
              class="form-field-freetext"
              matInput
              rows="5"
              placeholder="{{
                'underwriter-hints-step.insured-hints' | translate
              }}"
              [maxLength]="maxInsuredHintsLength"
            ></textarea>
            <mat-hint
              align="end"
              class="form-field-hint-primary"
            >
              <small
                >{{ formGroup?.get('freeText')?.value?.length ?? 0 }} /
                {{ maxInsuredHintsLength }}</small
              >
            </mat-hint>
            <mat-error *ngIf="getErrorsToDisplayFor('freeText')">
              <idv-error-help-block
                [errors]="getErrorsToDisplayFor('freeText')"
              ></idv-error-help-block>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-12">
          <button
            id="e2e-underwriter-hints-confirm-button"
            class="button-proceed"
            type="button"
            mat-raised-button
            color="primary"
            (click)="onClickProceed()"
          >
            {{ 'question-group.next' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
