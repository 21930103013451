import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable, Subject, zip } from 'rxjs';
import { ArrayToStringPipe } from '../../../../../../core-lib';
import { Config, Request } from '../../../../../../idv-lib';
import { StepStateService } from '../../../../../../idv-lib/src/lib/services/step-navigation/step-state.service';
import { RequestService } from '../../../../../../idv-lib/src/lib/services/request/request.service';
import { TranslateService } from '@ngx-translate/core';
import {
  ConfigAssetId,
  ConfigAssetService,
} from '../../../../../../idv-lib/src/lib/services/config/config-asset.service';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { LegalNotesService } from '../../../services/legal-notes/legal-notes.service';
import { TenantConfigService } from '../../../../../../idv-lib/src/lib/services/config/tenant-config.service';
import { TenantSpecificTextService } from '../../../../../../idv-lib/src/lib/services/tenant/tenant-specific-text.service';

export interface PersonalInfo {
  birthDate: string;
  fullName: string;
  gender: string;
  contractId: string;
}

@Component({
  selector: 'ins-intro-step',
  templateUrl: './intro-step.component.html',
  styleUrls: ['./intro-step.component.scss'],
})
export class IntroStepComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('scroller', { static: true }) scroller: ElementRef;
  @ViewChild('legalNotesCheckBox', { static: true }) legalNotesCheckBox: any;

  personalInfo: PersonalInfo;

  public legalNotesHaveBeenRead: boolean;
  public hasUserConfirmedLegalNotes: boolean;
  public hasErrorNotReadLegalNotes: boolean;
  public hasErrorNotConfirmed: boolean;
  public preContractualDisclosureHtml$: Observable<string>;
  public isAtBottomOfLegalNotes: boolean;

  public preContractualDisclosureDownloadLink$: Observable<string>;
  public preContractualDisclosureLabel$: Observable<string>;

  private destroyed$ = new Subject<void>();

  constructor(
    private stepStateService: StepStateService,
    private requestService: RequestService,
    private translateService: TranslateService,
    private legalNotesService: LegalNotesService,
    private arrayToString: ArrayToStringPipe,
    private tenantConfigService: TenantConfigService,
    private configAssetService: ConfigAssetService,
    private tenantSpecificTextService: TenantSpecificTextService
  ) {
    this.legalNotesHaveBeenRead = false;
  }

  onScroll(event: any) {
    if (
      event.srcElement.scrollHeight <=
      event.srcElement.scrollTop + event.srcElement.offsetHeight
    ) {
      this.setLegalNotesRead();
      this.isAtBottomOfLegalNotes = true;
    } else {
      this.isAtBottomOfLegalNotes = false;
    }
  }

  ngOnInit() {
    this.preContractualDisclosureDownloadLink$ =
      this.tenantSpecificTextService.getText(
        'insured_preContractualDisclosureDownloadLink'
      );
    this.preContractualDisclosureLabel$ =
      this.tenantSpecificTextService.getText(
        'insured_preContractualDisclosureLabel'
      );
    this.requestService.request
      .pipe(
        takeUntil(this.destroyed$),
        filter((request) => !!request)
      )
      .subscribe((request) => {
        this.personalInfo = this.fillPersonalInfo(request);
        if (request && request.epilogueQuestionnaire) {
          this.legalNotesHaveBeenRead = true;
          this.hasUserConfirmedLegalNotes = true;
          this.hasErrorNotReadLegalNotes = false;
          if (this.legalNotesCheckBox) {
            this.legalNotesCheckBox.checked = true;
            this.legalNotesCheckBox.disabled = true;
          }
        }
      });

    this.preContractualDisclosureHtml$ =
      this.configAssetService.loadMultiLanguageHtmlAsset$(
        ConfigAssetId['pre-contractual-disclosure']
      );
  }

  private fillPersonalInfo(request: Request): PersonalInfo {
    const applicant = request.applicant;

    const genderCode = applicant && applicant.gender;
    let gender = '';
    if (['male', 'female'].includes(genderCode)) {
      gender = this.translateService.instant(
        ['shared', 'genders', genderCode].join('.')
      );
    }

    const fullName = this.arrayToString.transform(
      [applicant.givenName, applicant.familyName],
      null,
      null,
      ' '
    );

    return {
      contractId: request.contractId,
      birthDate: applicant.birthDate,
      fullName,
      gender,
    };
  }

  ngAfterViewInit(): void {
    if (this.legalNotesHaveBeenRead) {
      this.legalNotesCheckBox.checked = true;
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  tickLegalNotes() {
    if (this.legalNotesHaveBeenRead) {
      this.hasUserConfirmedLegalNotes = this.legalNotesCheckBox.checked;
    } else {
      this.legalNotesCheckBox.checked = false;
      this.hasErrorNotReadLegalNotes = true;
    }

    if (this.hasUserConfirmedLegalNotes) {
      this.hasErrorNotConfirmed = false;
    }
  }

  private setLegalNotesRead() {
    this.legalNotesHaveBeenRead = true;
    this.hasErrorNotReadLegalNotes = false;
  }

  onClickDownloadPdf() {
    zip(
      this.configAssetService.loadMultiLanguageBlobAsset$(
        ConfigAssetId['pre-contractual-disclosure-pdf']
      ),
      this.tenantConfigService.tenantConfig$.pipe(
        map((config: Config) => config.preContractualDisclosurePdfDownloadName)
      )
    ).subscribe(([blob, fileName]) => {
      if (!fileName) {
        fileName = 'pre-contractual-disclosure.pdf';
      }
      saveAs(blob, fileName);
      this.setLegalNotesRead();
    });
  }

  onClickProceed() {
    if (this.hasUserConfirmedLegalNotes) {
      this.confirmCompletenessAndProceed();
    } else if (!this.legalNotesHaveBeenRead) {
      this.hasErrorNotReadLegalNotes = true;
    } else {
      this.hasErrorNotConfirmed = true;
    }
  }

  scrollMore() {
    if (!this.scroller.nativeElement.scrollBy) {
      this.scroller.nativeElement.scrollTop =
        this.scroller.nativeElement.scrollTop + 100;
    } else {
      this.scroller.nativeElement.scrollBy({
        top: 100,
        behavior: 'smooth',
      });
    }
  }

  private confirmCompletenessAndProceed() {
    this.legalNotesService
      .confirmLegalNotes(true)
      .pipe(switchMap(() => this.requestService.refetchRequest()))
      .subscribe(() => {
        this.stepStateService.navigateToNextStep();
      });
  }
}
