// tslint:disable:max-line-length
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IdvComponentsModule } from '../../../../idv-lib/src/lib/components/idv-components.module';
import { IdvInputControlsModule } from '../../../../idv-lib/src/lib/input-controls/idv-input-controls.module';
import { IdvQuestionnairesModule } from '../../../../idv-lib/src/lib/questionaires/idv-questionnaires.module';
import { InsComponentsModule } from '../components/ins-components.module';
import { AuthSecondFactorFrameComponent } from './auth-second-factor-frame/auth-second-factor-frame.component';

@NgModule({
  imports: [
    IdvComponentsModule,
    IdvInputControlsModule,
    RouterModule,
    IdvQuestionnairesModule,
    InsComponentsModule,
  ],
  declarations: [AuthSecondFactorFrameComponent],
})
export class InsAuthenticationModule {}
