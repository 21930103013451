import { Component } from '@angular/core';
import { StepState } from '../../../../../idv-lib/src/lib/services/step-navigation/step-state';

@Component({
  template: `
    <idv-master-detail-main
      [hierarchicalStepStates]="hierarchicalStepStates"
      [pseudoSteps]="true"
    >
      <ins-header></ins-header>
    </idv-master-detail-main>
  `,
})
export class AuthSecondFactorFrameComponent {
  hierarchicalStepStates: StepState[] = [
    {
      name: 'two-factor-authentication.security-check',
      isCompleted: false,
    },
  ];
}
