// tslint:disable:max-line-length
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IdvQuestionnairesModule } from '../../../../idv-lib/src/lib/questionaires/idv-questionnaires.module';
import { IdvComponentsModule } from '../../../../idv-lib/src/lib/components/idv-components.module';
import { IdvInputControlsModule } from '../../../../idv-lib/src/lib/input-controls/idv-input-controls.module';
import { MainComponent } from './master-detail-main/main.component';
import { ConfirmCompletenessComponent } from './step-components/epilogue-step/confirm-completeness/confirm-completeness.component';
import { IntroStepComponent } from './step-components/intro-step/intro-step.component';
import { InsComponentsModule } from '../components/ins-components.module';
import { CompleteSummaryComponent } from './step-components/epilogue-step/complete-summary/complete-summary.component';
import { UnderwriterHintsStepComponent } from './step-components/underwriter-hints-step/underwriter-hints-step.component';

@NgModule({
  imports: [
    IdvComponentsModule,
    IdvInputControlsModule,
    RouterModule,
    IdvQuestionnairesModule,
    InsComponentsModule,
  ],
  declarations: [
    MainComponent,
    ConfirmCompletenessComponent,
    IntroStepComponent,
    UnderwriterHintsStepComponent,
    CompleteSummaryComponent,
  ],
})
export class InsQuestionnairesModule {}
