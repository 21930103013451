import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MrcCoreModule } from '../../../core-lib';
import { AuthorizationHeaderInterceptor } from '../../../core-lib';
import { MrcComponentsModule } from '../../../core-lib';
import { AcceptLanguageHeaderInterceptor } from '../../../core-lib';
import { ActivityTrackingInterceptor } from '../../../core-lib';
import { ContentTypeHeaderInterceptor } from '../../../core-lib';
import { RetryInterceptor } from '../../../core-lib';
import { UncaughtErrorHandler } from '../../../core-lib';
import { MrcTrackingService } from '../../../core-lib';
import { IdvAuthenticationModule } from '../../../idv-lib/src/lib/authentication/idv-authentication.module';
import { IdvComponentsModule } from '../../../idv-lib/src/lib/components/idv-components.module';
import {
  IDV_ENV,
  IdvEnvironment,
} from '../../../idv-lib/src/lib/interfaces/idv-environment';
import { TrackingService } from '../../../idv-lib/src/lib/services/misc/tracking.service';
import { StepStateService } from '../../../idv-lib/src/lib/services/step-navigation/step-state.service';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InsAuthenticationModule } from './authentication/ins-authentication.module';
import { InsQuestionnairesModule } from './questionnaires/ins-questionnaires.module';
import { InsStepStateService } from './services/step-navigation/ins-step-state.service';
import { ApplicationNameHeaderInterceptor } from '../../../idv-lib/src/lib/authentication/application-name-header-interceptor';
import { LegalNotesService } from './services/legal-notes/legal-notes.service';
import { IdvServicesModule } from '../../../idv-lib/src/lib/services/idv-services.module';
import { InsuredHintsService } from './services/insured-hints/insured-hints.service';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    MrcCoreModule.forRoot(),
    MrcComponentsModule,
    IdvComponentsModule,
    IdvServicesModule.forRoot(),
    IdvAuthenticationModule,
    InsQuestionnairesModule,
    InsAuthenticationModule,
    AppRoutingModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    { provide: IDV_ENV, useValue: environment },
    { provide: ErrorHandler, useClass: UncaughtErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ActivityTrackingInterceptor,
      multi: true,
    }, // This must come before the RetryInterceptor
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentTypeHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      deps: [IDV_ENV],
      // tslint:disable-next-line:no-shadowed-variable
      useFactory: (environment: IdvEnvironment) =>
        new RetryInterceptor(environment.httpRequests),
      multi: true,
    },
    { provide: MrcTrackingService, useClass: TrackingService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplicationNameHeaderInterceptor,
      multi: true,
    },
    { provide: StepStateService, useClass: InsStepStateService },
    { provide: LegalNotesService, useClass: LegalNotesService },
    { provide: InsuredHintsService, useClass: InsuredHintsService },
  ],
})
export class AppModule {}
