<idv-demo-indicator *ngIf="isDemo | async"></idv-demo-indicator>

<header class="d-none d-lg-block d-xl-block">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <div
          class="logo tenant-logo"
          [style.backgroundImage]="logo$ | async"
        ></div>
      </div>
      <div class="col-5">
        <div
          class="patient-info"
          [class.no-info]="!applicantInfo"
        >
          <div class="heading">
            {{ 'case-header.labels.title' | translate }}
          </div>
          <div
            *ngIf="applicantInfo"
            class="info-content"
          >
            {{ applicantInfo }}
          </div>
        </div>
      </div>
      <div class="col-1 col-action">
        <button
          mat-mini-fab
          mat-stroked-button
          [matMenuTriggerFor]="menu"
          id="idv_main_component_menu_trigger"
          idvClickTracker="idv_more_menu_btn_clicked"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu
          #menu="matMenu"
          class="menu"
          [overlapTrigger]="true"
          xPosition="before"
        >
          <div
            mat-menu-item
            (click)="openGDPRDialog()"
            class="menu-item-wrapper"
            idvClickTracker="idv_open_gdpr_dialog?source=more_menu"
          >
            <span class="menu-item">{{
              'case-header.labels.data-privacy' | translate
            }}</span>
          </div>
          <div
            mat-menu-item
            (click)="openFaqModal()"
            class="menu-item-wrapper"
            idvClickTracker="idv_open_faq?source=more_menu"
          >
            <span class="menu-item">{{
              'case-header.labels.faq' | translate
            }}</span>
          </div>
          <div
            mat-menu-item
            (click)="logOut()"
            class="menu-item-wrapper"
            idvClickTracker="idv_click_logout?source=more_menu"
          >
            <span class="menu-item">{{
              'case-header.labels.logout' | translate
            }}</span>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
</header>
