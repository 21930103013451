<div class="row">
  <div class="col">
    <h2>{{ 'completeness-and-download.heading' | translate }}</h2>
    <p>{{ 'completeness-and-download.intro-text' | translate }}</p>
  </div>
</div>

<div
  id="underwriter-hints"
  class="row hints-row"
  *ngIf="request?.underwriterHints"
>
  <div class="col">
    <h2>{{ 'step-navigation.step-labels.underwriter-hints' | translate }}</h2>
    <p>{{ request.underwriterHints.freeText }}</p>
  </div>
</div>

<div
  id="insured-hints"
  class="row hints-row"
  *ngIf="request?.insuredHints"
>
  <div class="col">
    <div class="d-flex">
      <div class="hints-content">
        <h2>{{ 'underwriter-hints-step.insured-hints' | translate }}</h2>
        <p>{{ request.insuredHints.freeText }}</p>
      </div>
      <div class="edit-button-container">
        <idv-edit-button
          [routerLink]="'../underwriter-hints'"
          (click)="onClickEditHintsButton()"
        ></idv-edit-button>
      </div>
    </div>
  </div>
</div>

<ins-complete-summary
  class="section-summary"
  [request]="request"
>
</ins-complete-summary>

<form
  [formGroup]="formGroup"
  class="d-block section-checkboxes"
>
  <div class="row">
    <div class="col">
      <mat-checkbox
        id="e2e-confirm-completeness-checkbox-0"
        type="checkbox"
        formControlName="check1"
      >
        {{ 'completeness-and-download.check-1' | translate }}
      </mat-checkbox>
      <mat-error *ngIf="showErrors && isInvalid('check1')">
        <div class="checkbox-error-message">
          {{ 'completeness-and-download.not-checked-error' | translate }}
        </div>
      </mat-error>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-checkbox
        id="e2e-confirm-completeness-checkbox-1"
        type="checkbox"
        formControlName="check2"
      >
        {{ 'completeness-and-download.check-2' | translate }}
      </mat-checkbox>
      <mat-error *ngIf="showErrors && isInvalid('check2')">
        <div class="checkbox-error-message">
          {{ 'completeness-and-download.not-checked-error' | translate }}
        </div>
      </mat-error>
    </div>
  </div>
</form>

<div class="row section-button">
  <div class="col">
    <button
      class="complete-button"
      [class.like-disabled]="formGroup.invalid"
      type="button"
      id="btn_idv_confirm_completeness"
      mat-raised-button
      color="primary"
      [disabled]="!allStepsCompleted"
      (click)="confirm()"
      idvClickTracker="idv_confirm_and_download_clicked"
    >
      <mat-icon>get_app</mat-icon><br />
      {{ 'completeness-and-download.button' | translate }}
    </button>
  </div>
</div>
