import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Request, Risk } from '../../../../../../../idv-lib/src/lib/interfaces';
import {
  hasFilledRiskCategory,
  riskCategories,
  RiskCategory,
} from '../../../../../../../idv-lib/src/lib/services/request/request.service';
import { isRiskVisibleToInsured } from '../../../../../../../idv-lib/src/lib/utils/risks';

@Component({
  selector: 'ins-complete-summary',
  templateUrl: './complete-summary.component.html',
  styleUrls: ['./complete-summary.component.scss'],
})
export class CompleteSummaryComponent implements OnChanges {
  @Input()
  request: Request;

  riskCategories: RiskCategory[];

  risksByCategory: { [categoryKey: string]: Risk[] };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['request'] && this.request) {
      this.riskCategories = riskCategories.filter(
        hasFilledRiskCategory(this.request, isRiskVisibleToInsured)
      );
      this.risksByCategory = {};
      this.riskCategories.forEach((category) => {
        this.risksByCategory[category.key] = this.request[
          category.key
        ].risks.filter(isRiskVisibleToInsured);
      });
    }
  }
}
