import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  Applicant,
  Request,
} from '../../../../../../../idv-lib/src/lib/interfaces';
import { FileSaveHelperService } from '../../../../../../../idv-lib/src/lib/services/misc/file-save-helper.service';
import { TrackingService } from '../../../../../../../idv-lib/src/lib/services/misc/tracking.service';
import { RequestService } from '../../../../../../../idv-lib/src/lib/services/request/request.service';
import {
  StepName,
  StepStateService,
} from '../../../../../../../idv-lib/src/lib/services/step-navigation/step-state.service';
import { TenantService } from '../../../../../../../idv-lib/src/lib/services/tenant/tenant.service';
import { EpilogueService } from '../../../../../../../idv-lib/src/lib/services/request/epilogue/epilogue.service';

@Component({
  selector: 'ins-confirm-completeness',
  templateUrl: './confirm-completeness.component.html',
  styleUrls: ['./confirm-completeness.component.scss'],
})
export class ConfirmCompletenessComponent implements OnInit, OnDestroy {
  @ViewChild('snackbarContainerRef', { read: ViewContainerRef })
  snackbarContainerRef: ViewContainerRef;

  @ViewChild('snackbarOrigin') snackbarOrigin: TemplateRef<any>;
  @ViewChild('snackbarTemplate') snackbarTemplate: TemplateRef<any>;

  private errorSnackbar: MatSnackBarRef<any>;
  private destroyed$ = new Subject<void>();

  formGroup: UntypedFormGroup;
  showErrors = false;

  request: Request;

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private requestService: RequestService,
    private stepStateService: StepStateService,
    private fileSaveHelperService: FileSaveHelperService,
    private trackingService: TrackingService,
    private fb: UntypedFormBuilder,
    private tenantService: TenantService,
    private epilogueService: EpilogueService
  ) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      check1: [false, checkRequired],
      check2: [false, checkRequired],
    });
    this.requestService.request
      .pipe(takeUntil(this.destroyed$))
      .subscribe((request) => {
        this.request = request;
        if (!this.allStepsCompleted) {
          this.showErrorSnackbar();
        }
      });
  }

  isInvalid(name: string): boolean {
    return this.formGroup.get(name).invalid;
  }

  get allStepsCompleted() {
    return this.stepStateService.areAllRequiredStepsCompleted();
  }

  private showErrorSnackbar() {
    this.translateService
      .get([
        'confirm-completeness.missing-steps',
        'confirm-completeness.snackbar-action',
      ])
      .subscribe((values: any) => {
        this.errorSnackbar = this.snackBar.open(
          values['confirm-completeness.missing-steps'],
          values['confirm-completeness.snackbar-action'],
          {
            panelClass: [
              'sub-header-snack-bar',
              'error',
              'confirm-completeness-snack-bar',
            ],
            verticalPosition: 'top',
          }
        );

        this.errorSnackbar.onAction().subscribe(() => {
          this.stepStateService.goToNextRiskStep();
        });
      });
  }

  confirm() {
    this.showErrors = true;
    if (this.formGroup.valid) {
      this.confirmCompleteness();
    }
  }

  private confirmCompleteness() {
    if (!this.allStepsCompleted) {
      this.showErrorSnackbar();
    } else {
      this.epilogueService
        .answerEpilogueQuestionnaire({
          epilogueConfirmed: true,
        })
        .subscribe(() => {
          this.downloadPdfAndSubmit();
        });
    }
  }

  private downloadPdfAndSubmit() {
    this.requestService
      .getRequestSummary()
      .pipe(
        switchMap((blob) => {
          this.trackingService.trackEvent('idv_got_pdf_summary');
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          return new Observable<string>((observer) => {
            reader.onload = () => {
              this.trackingService.trackEvent('idv_file_reader_loaded');
              observer.next(reader.result.toString());
              observer.complete();
            };
            reader.onerror = (error) => {
              observer.error(error);
            };
          });
        }),
        switchMap((result) => {
          return this.requestService.submitRequest().pipe(
            tap(() => {
              this.trackingService.trackEvent('idv_submitted');
              this.stepStateService.complete(StepName.complete);
              this.stepStateService.getFlatStepStates().forEach((step) => {
                step.isLocked = true;
              });
              this.saveSummaryToFileSystem(result);
              this.trackingService.trackEvent('idv_downloaded');
            })
          );
        }),
        catchError((error) => {
          this.trackingService.trackException(error);
          return throwError(error);
        })
      )
      .subscribe((_) => {
        this.logout();
      });
  }

  private logout(): void {
    this.stepStateService.navigateToLogout(this.translateService.currentLang);
  }

  onClickEditHintsButton() {
    this.trackingService.trackEvent('idv_click_edit_hints_button');
  }

  // ------------------------------------------ PDF download ----------------------------------------

  private saveSummaryToFileSystem(pdfAsBase64: string) {
    const blob = this.fileSaveHelperService.dataURItoBlob(pdfAsBase64);
    saveAs(blob, this.getFileName());
  }

  private getFileName(): string {
    const label = this.translateService.instant(
      'print-questioning-details.filename-part'
    );
    const applicant = this.request.applicant || ({} as Applicant);
    const { familyName, givenName } = applicant;
    const tenant = this.tenantService.current;
    const baseName = [tenant, label, familyName, givenName]
      .filter((s) => !!s)
      .map((s) => s.trim().replace(/ +/g, '-'))
      .join('-');
    return `${baseName}.pdf`;
  }

  ngOnDestroy(): void {
    if (this.errorSnackbar) {
      this.errorSnackbar.dismiss();
    }
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

function checkRequired(control: UntypedFormControl) {
  return !control.value ? { required: true } : null;
}
