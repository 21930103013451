import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { ArrayToStringPipe } from '../../../../../core-lib/src/lib/components/pipes/array-to-string.pipe';
import { RequestService } from '../../../../../idv-lib/src/lib/services/request/request.service';
import { ResponsiveService } from '../../../../../idv-lib/src/lib/services/responsive/responsive.service';
import { StepStateService } from '../../../../../idv-lib/src/lib/services/step-navigation/step-state.service';

@Component({
  templateUrl: './main.component.html',
})
export class MainComponent implements OnInit {
  public isCompact$: Observable<boolean>;

  applicantInfo$: Observable<string>;

  constructor(
    private responsiveService: ResponsiveService,
    private stepStateService: StepStateService,
    private requestService: RequestService,
    private arrayToString: ArrayToStringPipe
  ) {}

  getHierarchicalStepStates() {
    return this.stepStateService.getHierarchicalStepStates();
  }

  ngOnInit() {
    this.isCompact$ = this.responsiveService.isCompact$;

    this.applicantInfo$ = this.requestService.request.pipe(
      filter((request) => !!request),
      map((request) => {
        if (!request.applicant) {
          return '';
        }
        const applicant = request.applicant;
        return this.arrayToString.transform(
          [applicant.givenName, applicant.familyName],
          null,
          null,
          ' '
        );
      })
    );
  }
}
