import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IdvEnvironment,
  IDV_ENV,
} from '../../../../../idv-lib/src/lib/interfaces/idv-environment';

@Injectable({ providedIn: 'root' })
export class LegalNotesService {
  constructor(
    private httpClient: HttpClient,
    @Inject(IDV_ENV) private environment: IdvEnvironment
  ) {}

  public confirmLegalNotes(confirmParagraph19: boolean): Observable<void> {
    return this.httpClient.post<void>(
      `${this.environment.apiHost}/ins/myRequest/legal/confirm`,
      confirmParagraph19
    );
  }
}
