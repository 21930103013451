import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../environments/environment';
import { UncaughtErrorService } from '../../../core-lib';
import { WindowService } from '../../../core-lib';
import { Router } from '@angular/router';
import { TenantService } from '../../../idv-lib/src/lib/services/tenant/tenant.service';
import { Subject, Observable } from 'rxjs';
import { first, takeUntil, startWith, tap } from 'rxjs/operators';
import {
  AuthenticationService,
  expirationQueryParameter,
} from '../../../idv-lib/src/lib/authentication/authentication.service';
import { AppRoute } from '../../../idv-lib/src/lib/interfaces/app-routing.const';
import { RequestService } from '../../../idv-lib/src/lib/services/request/request.service';
import { TenantConfigService } from '../../../idv-lib/src/lib/services/config/tenant-config.service';
import { ScriptService } from '../../../core-lib/src/lib/core/services/dom/script.service';
import { MtCaptchaDomService } from '../../../core-lib/src/lib/core/captcha/services/mt-captcha-dom.service';
import { LanguageService } from '../../../core-lib/src/lib/core/services/language/language.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'idv-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  uncaughtError: Error;

  public isSessionErrorShown: boolean;
  public isAppLanguageLoaded$: Observable<boolean>;

  private destroyed$ = new Subject<void>();

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private uncaughtErrorService: UncaughtErrorService,
    private windowService: WindowService,
    private router: Router,
    private ngZone: NgZone,
    private authenticationService: AuthenticationService,
    private tenantService: TenantService,
    private requestService: RequestService,
    private languageService: LanguageService,
    private tenantConfigService: TenantConfigService,
    private _scriptService: ScriptService,
    private _captchaService: MtCaptchaDomService
  ) {
    this.fixUsedBootstrapVersionForNgxBootstrap();
  }

  ngOnInit(): void {
    this._captchaService.initialize();

    this.uncaughtErrorService.uncaughtError
      .pipe(takeUntil(this.destroyed$))
      .subscribe((uncaughtError) => {
        if (
          uncaughtError &&
          !uncaughtError['riskRemoved'] &&
          !uncaughtError['questionChanged']
        ) {
          if (this.isErrorScreenEnabled) {
            this.uncaughtError = uncaughtError;
          } else {
            this.uncaughtError = uncaughtError;
            this.openErrorSnackBar();
          }
        }
      });

    this.requestService.sessionError
      .pipe(takeUntil(this.destroyed$))
      .subscribe((hasSessionError) => {
        if (hasSessionError) {
          this.cleanSession();
          this.redirectToLogin();
        }
      });

    this.isAppLanguageLoaded$ =
      this.languageService.applicationLanguageAvailable$.pipe(
        takeUntil(this.destroyed$),
        startWith(false)
      );

    this.tenantConfigService.tenantConfig$
      .pipe(
        tap((config) => {
          if (!!config.adobeAnalyticsUrl) {
            this.setupAdobeAnalytics(config.adobeAnalyticsUrl);
          }
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  private openErrorSnackBar() {
    this.ngZone.run(() => {
      this.translateService
        .get('error-screen.an-error-occurred')
        .subscribe((value: string) => {
          this.snackBar.open(value, ' ', {
            panelClass: 'debug-error-snackbar',
          });
        });
    });
  }

  private cleanSession(): void {
    this.authenticationService.isLoggedIn
      .pipe(first())
      .subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          this.authenticationService.logOut();
        }
      });
  }

  private redirectToLogin(): void {
    this.tenantService.tenant.subscribe((tenant) => {
      if (tenant) {
        this.router.navigate([AppRoute.login, tenant], {
          queryParams: expirationQueryParameter,
        });
      } else {
        this.router.navigate([AppRoute.login], {
          queryParams: expirationQueryParameter,
        });
      }
    });
  }

  get isErrorScreenEnabled() {
    return environment.isErrorScreenEnabled;
  }

  private fixUsedBootstrapVersionForNgxBootstrap() {
    this.windowService.window['__theme'] = 'bs3';
  }

  private setupAdobeAnalytics(adobeAnalyticsUrl: string) {
    this._scriptService.addScript(adobeAnalyticsUrl);
  }

  get currentLanguage(): string {
    return this.translateService.currentLang;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
