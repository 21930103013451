import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { RequestService } from '../../../../../../idv-lib/src/lib/services/request/request.service';
import {
  StepName,
  StepStateService,
} from '../../../../../../idv-lib/src/lib/services/step-navigation/step-state.service';
import { NO_HTML_TAGS_ALLOWED_PATTERN } from '../../../../../../physician/src/app/utilities/allowed-characters.const';
import { InsuredHintsService } from '../../../services/insured-hints/insured-hints.service';

@Component({
  selector: 'ins-underwriter-hints-step',
  templateUrl: './underwriter-hints-step.component.html',
  styleUrls: ['./underwriter-hints-step.component.scss'],
})
export class UnderwriterHintsStepComponent implements OnInit, OnDestroy {
  private _destroyed$ = new Subject<void>();

  public underwriterHintsFreeText$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  public readonly formGroup: UntypedFormGroup;
  public readonly maxInsuredHintsLength = 1000;

  constructor(
    private _requestService: RequestService,
    private _stepStateService: StepStateService,
    private _insuredHintsService: InsuredHintsService,
    private _formBuilder: UntypedFormBuilder
  ) {
    this.formGroup = this._formBuilder.group({
      freeText: [
        '',
        [
          Validators.required,
          Validators.maxLength(this.maxInsuredHintsLength),
          this.htmlValidator,
        ],
      ],
    });
  }

  htmlValidator(formControl: UntypedFormControl) {
    let text = formControl.value;
    if (!text) {
      return true;
    }
    let lines = text.split('\n');
    let isValid = true;
    const regex = new RegExp(NO_HTML_TAGS_ALLOWED_PATTERN);
    lines.forEach((line: string) => {
      if (regex.test(line) === false) {
        isValid = false;
      }
    });
    return isValid
      ? null
      : {
          pattern: {
            valid: false,
          },
        };
  }

  ngOnInit() {
    this._requestService.request
      .pipe(
        takeUntil(this._destroyed$),
        filter((request) => !!request)
      )
      .subscribe((request) => {
        this.underwriterHintsFreeText$.next(
          request.underwriterHints?.freeText ?? ''
        );
        this.formGroup
          .get('freeText')
          .setValue(request.insuredHints?.freeText ?? '');
      });
  }

  public onClickProceed() {
    if (this.underwriterHintsFreeText$.value) {
      if (!this.isValid()) {
        return;
      }
      this._insuredHintsService
        .answer(this.formGroup.get('freeText').value)
        .pipe(switchMap(() => this._requestService.refetchRequest()))
        .subscribe(() => {
          this.onProceed();
        });
    } else {
      this.onProceed();
    }
  }

  onProceed() {
    this._stepStateService.complete(StepName.underwriterHints);
    this._stepStateService.navigateToNextStep();
  }

  getErrorsToDisplayFor(field: string) {
    return this.formGroup?.get(field)?.errors;
  }

  isValid(): boolean {
    return this.formGroup.valid && !this.formGroup?.get('freeText')?.errors;
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
